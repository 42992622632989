import { useAuth } from "../context/AuthContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface ApiRequestParams {
    signal?: AbortSignal;
    endpoint: string; // Endpoint relative to base URL (e.g., '/api/v1/kb_conversations')
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE'; // HTTP method, default is 'GET'
    queryParams?: Record<string, string | number>; // Query parameters for the URL
    body?: any; // Request body for POST/PUT requests
    headers?: Record<string, string>; // Additional headers (if needed)
    transformResponse?: (res: any) => any;  // Optional transform function
}

export const useApiRequest = () => {
    const { token } = useAuth();
    // console.log("token", token)
    const apiRequest = async ({ signal, endpoint, method = 'GET', queryParams = {}, body, headers = {}, transformResponse }: ApiRequestParams): Promise<any> => {
        const url = new URL(`${API_BASE_URL}${endpoint}`);
        
        // Append query parameters to URL
        Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key].toString()));

        // Set default headers (Authorization, Content-Type)
        const defaultHeaders: Record<string, string> = {
            'Authorization': token,  // Directly using token without 'Bearer ' prefix
            'Content-Type': 'application/json',
            ...headers // Allow custom headers to override defaults
        };

        // Create request options
        const options: RequestInit = {
            method,
            headers: defaultHeaders,
            signal,
        };

        // Add body for POST/PUT requests
        if (body) {
            options.body = JSON.stringify(body);
        }
        console.log("URL", url)
        console.log("options", options)
        // Execute the fetch request
        const response = await fetch(url.toString(), options);
        // Handle response
        // if (response.ok) {
            
        // }
        // const languageHeader = response.headers.get('X-Language');
        // const languageHeader2 = response.headers.get('Vary');


        // console.log("languageHeader", languageHeader)
        // console.log("languageHeader2", languageHeader2)

        if (transformResponse) {
            return transformResponse(response);
        }
        return response.json(); // Default handling as JSON

        // Handle errors
        const errorData = await response.json();
        const error = new Error(`Error: ${response.status} ${response.statusText}`);
        (error as any).message = errorData.message || 'An error occurred during the request';
        throw error;
    };

    return { apiRequest };
};
