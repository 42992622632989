// src/hooks/useConversations.ts
import { useApiRequest } from './useApiRequest';

interface FetchConversationsParams {
    signal: AbortSignal;
    page?: number;
    page_size?: number;
    last_conversation_id?: string;
    order_by?: string;
}

interface createConversationsParams {
    name?: string;
    reference_conversation_id?: string;
}

interface updateConversationParams {
    name: string;
    conversation_id: string;
}

interface deleteConversationParams {
    conversation_id: string;
}

interface FetchConversationMessagesParams {
    conversation_id: string;
    signal: AbortSignal;
    page?: number;
    page_size?: number;
    last_message_id?: any | string;
}

interface SendConversationMessagesParams {
    conversation_id: null | string;
    message: string;
}

export const useConversations = () => {
    const { apiRequest } = useApiRequest();

    const fetchConversations = async (params: FetchConversationsParams): Promise<any> => {
        const queryParams: Record<string, string | number> = {};
        if (params.page !== undefined) queryParams.page = params.page;
        if (params.page_size !== undefined) queryParams.page_size = params.page_size;
        if (params.last_conversation_id) queryParams.last_conversation_id = params.last_conversation_id;
        if (params.order_by) queryParams.order_by = params.order_by;

        return apiRequest({
            signal: params.signal,
            endpoint: '/api/v1/kb_conversations',
            queryParams,
            transformResponse: (response) => response.json().then((data: any) => {
                return data.data
            }),
        });
    };

    const createConversation = async (params: createConversationsParams): Promise<any> => {
        const queryParams: Record<string, string> = {};

        if (params.name !== undefined) queryParams.name = params.name;
        if (params.reference_conversation_id !== undefined) queryParams.reference_conversation_id = params.reference_conversation_id;

        return apiRequest({
            method: 'POST',
            endpoint: '/api/v1/kb_conversations',
            queryParams,
            transformResponse: (response) => response.json().then((data: any) => data.data),
        });
    }

    const updateConversation = async (params: updateConversationParams): Promise<any> => {
        const queryParams: Record<string, string | number> = {};
        if (params.conversation_id !== undefined) queryParams.conversation_id = params.conversation_id;
        if (params.name !== undefined) queryParams.name = params.name;

        return apiRequest({
            method: 'PUT',
            queryParams,
            endpoint: '/api/v1/kb_conversations',
            transformResponse: (response) => response.json().then((data: any) => data.data),
        });
    }

    const deleteConversation = async (params: deleteConversationParams): Promise<any> => {
        const queryParams: Record<string, string | number> = {};
        if (params.conversation_id !== undefined) queryParams.conversation_id = params.conversation_id;

        return apiRequest({
            method: 'DELETE',
            queryParams,
            endpoint: '/api/v1/kb_conversations',
            transformResponse: (response) => response.json().then((data: any) => data.data),
        });

    }

    const fetchConversationMessages = async (params: FetchConversationMessagesParams): Promise<any> => {
        const queryParams: Record<string, string | number> = {};
        if (params.page !== undefined) queryParams.page = params.page;
        if (params.page_size !== undefined) queryParams.page_size = params.page_size;
        if (params.conversation_id !== undefined) queryParams.conversation_id = params.conversation_id;
        if (params.last_message_id !== undefined) queryParams.last_message_id = params.last_message_id;

        return apiRequest({
            signal: params.signal,
            queryParams,
            endpoint: `/api/v1/kb_conversations/${params.conversation_id}/messages`,
            transformResponse: async (response) => {
                const data = await response.json(); // Parse JSON from the response
                return {
                    ...data,
                    data: data.data.reverse() // Reverse the messages as required
                };
            }
        });
    }

    const sendConversationMessages = async (params: SendConversationMessagesParams): Promise<any> => {

        return apiRequest({
            method: 'POST',
            body: { message: params.message },
            endpoint: `/api/v1/kb_conversations/${params.conversation_id}/messages`,
            transformResponse: (response) => {
                const languageHeader = response.headers.get('X-Language');
                const language = languageHeader?.split(" ").pop();

                return {
                    language,
                    status: response.status,
                    responseStream: response.body as ReadableStream
                }
            }

        });
    }

    return { fetchConversations, createConversation, updateConversation, deleteConversation, fetchConversationMessages, sendConversationMessages };
};

