export const getLanguageCode = (language: string | null | undefined): string => { 
    const languageCodeMap: { [key: string]: string } = {
        English: "en-US", // English maps to en-US
        French: "fr-FR",  // French maps to fr-FR
    };
    
    const normalizedLanguage = language ? language.trim().replace(/[.,!?;]$/, "") : "";

    // Check if language is valid and exists in the map
    const languageCode = languageCodeMap[normalizedLanguage.charAt(0).toUpperCase() + normalizedLanguage.slice(1)];

    if (!languageCode) {
        return "en-US";
    }

    return languageCode;
}